import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

const BudgetsPage = () => {
  const location = useLocation();

  if (location.pathname === '/budgets') {
    return <div>BudgetsPage</div>;
  }

  return <Outlet />;
};

export default BudgetsPage;
