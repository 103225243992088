import isMobile from 'is-mobile';
import { useForm } from 'react-hook-form';

import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useWidth } from '@/hooks/useWidth';
import toast from 'react-hot-toast';
import loginBrokenImg from '@/assets/images/login-broken.svg';
import Logo from '@/assets/images/logo512.png';
import { authService } from '@/services/auth.service';

import Checkbox from '../atoms/Checkbox';
import InputField from '../atoms/InputField';
import PasswordField from '../atoms/PasswordField';
import InfoModal from '../molecules/InfoModal';
import { Button } from '../ui/button';
import CountdownTimer from '@/components/atoms/CountDownTimer';

interface LoginFormData {
  email: string;
  password: string;
  rememberMe?: boolean;
}

/**
 * Retrieves the stored login state from localStorage.
 *
 * @returns {Object | null} The stored login state, or null if it doesn't exist or has expired.
 */
const getStoredLoginState = () => {
  const storedState = localStorage.getItem('loginState');
  if (!storedState) return null;

  const state = JSON.parse(storedState);
  const expiryTime = new Date(state.expiryTime);

  if (new Date() > expiryTime) {
    localStorage.removeItem('loginState');
    return null;
  }

  return state;
};

/**
 * Stores the current login state in localStorage.
 *
 * @param {number} attempts - The number of login attempts.
 * @param {boolean} timerActive - Indicates if the timer is active.
 */
const storeLoginState = (attempts: number, timerActive: boolean) => {
  const expiryTime = new Date();
  expiryTime.setMinutes(expiryTime.getMinutes() + 1);

  localStorage.setItem(
    'loginState',
    JSON.stringify({
      attempts,
      timerActive,
      expiryTime: expiryTime.toISOString()
    })
  );
};

const LoginForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(() => {
    const stored = getStoredLoginState();
    return stored?.attempts || 0;
  });
  const [isTimerActive, setIsTimerActive] = useState(() => {
    const stored = getStoredLoginState();
    return stored?.timerActive || false;
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<LoginFormData>();

  const width = useWidth();
  const isMobileView = width < 768 || isMobile() ? true : false;

  useEffect(() => {
    if (isTimerActive) {
      setShowSuccess(true);
    }
  }, []);

  const remainingTime = useMemo(() => {
    const state = getStoredLoginState();
    if (!state?.expiryTime) return 60;
    return Math.max(Math.floor((new Date(state.expiryTime).getTime() - Date.now()) / 1000), 0);
  }, []);

  const handleTryAgainClick = () => {
    if (!isTimerActive && loginAttempts < 1) setShowSuccess(false);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  const onSubmit = async (data: LoginFormData) => {
    try {
      await authService.login({
        email: data.email,
        password: data.password
      });
      toast.success('Logged in successfully!');
      localStorage.removeItem('loginState'); // Clear on successful login
      navigate('/dashboard');
    } catch (error: any) {
      const newAttempts = loginAttempts + 1;
      setLoginAttempts(newAttempts);

      if (newAttempts > 2) {
        setShowSuccess(true);
        setIsTimerActive(true);
        storeLoginState(newAttempts, true);
      } else {
        storeLoginState(newAttempts, false);
        toast.error(error.message || 'Login failed. Please try again.');
      }
    }
  };

  return (
    <div
      className={`pb-10 h-full max-w-[616px] mx-auto laptop-h:h-fit md:pr-7 md:pt-8 border md:border-0 ${showSuccess ? 'flex flex-col justify-center' : ''}`}
    >
      {(!showSuccess || isMobileView) && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`max-w-[450px] mt-7 gap-5 mx-auto flex flex-col ${isMobileView ? 'justify-between' : 'justify-center'} py-10 px-5 md:px-0 h-full laptop-h:h-fit`}
        >
          <div>
            <img className="max-w-[130px] mx-auto md:hidden" src={Logo} alt="batjet-logo" />
            <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">Sign In</h1>
            <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
              Sign in to access your personalized dashboard and stay updated with the latest features.
            </p>
            <InputField
              className="my-4 mt-5"
              label={'Username / Mail'}
              placeholder="Input here"
              name="email"
              type="text"
              register={register}
            />
            <PasswordField
              className="mb-3"
              label={'Password'}
              description="Password must be consisting minimum 8 characters"
              placeholder="Enter password..."
              name="password"
              register={register}
            />

            <div className="flex items-center justify-between">
              <Checkbox
                name="agreeTerms"
                label={<p className="text-xs font-medium md:text-sm text-secondary leading-[0.9125rem]">Remeber me</p>}
              />
              <button onClick={handleForgotPasswordClick} type="button" className="text-xs text-primary font-medium">
                forget your password ?
              </button>
            </div>
          </div>
          <div>
            <Button
              type="submit"
              className="my-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px]"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'SIGNING IN...' : 'SIGN IN'}
            </Button>
            <p className="text-sm md:text-lg text-center">
              Don't have an account?{' '}
              <button disabled={isSubmitting} onClick={handleSignUpClick} className="text-primary font-bold">
                Sign up
              </button>
            </p>
          </div>
        </form>
      )}

      {showSuccess && !isMobileView && (
        <>
          <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">Sign In</h1>
          <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
            Sign in to access your personalized dashboard and stay updated with the latest features.
          </p>
          <div className="mt-14 w-full flex flex-col items-center justify-center gap-3">
            <img className="w-[144px] h-[144px]" src={loginBrokenImg} alt="success-img" />
            <p className="font-bold text-2xl">You’ve failed to log in 3 times</p>
            <p className="text-secondary text-[18px] leading-[22px] text-center mx-20">
              You need to wait before you can try logging in again, if you are unsure, you can reset your password.
            </p>
          </div>
          <Button
            onClick={handleTryAgainClick}
            className="mt-[4.25rem] mb-5 rounded-[24px] text-base font-semibold px-6 h-[64px] text-[18px] w-full"
            variant={isTimerActive ? 'outline' : 'default'}
          >
            {isTimerActive ? (
              <CountdownTimer
                duration={60}
                timeLeft={remainingTime}
                isActive={isTimerActive}
                onActiveChange={setIsTimerActive}
                onComplete={() => {
                  setLoginAttempts(0);
                  setIsTimerActive(false);
                  localStorage.removeItem('loginState');
                }}
              />
            ) : (
              <span>TRY AGAIN</span>
            )}
          </Button>
          <a href="#" className="text-[18px] text-center text-primary font-medium">
            forget your password ?
          </a>
        </>
      )}

      {showSuccess && isMobileView && (
        <InfoModal
          isOpen={showSuccess}
          variant="success"
          title="You’ve failed to log in 3 times"
          message={
            <p>
              You need to wait before you can try logging in again, if you are unsure, you can{' '}
              <a href="#" className="text-primary">
                reset your password
              </a>
              .
            </p>
          }
          buttonText={
            isTimerActive ? (
              <span className="text-white font-semibold">
                <CountdownTimer
                  duration={60}
                  isActive={isTimerActive}
                  timeLeft={remainingTime}
                  onActiveChange={setIsTimerActive}
                  onComplete={() => {
                    setLoginAttempts(0);
                    setIsTimerActive(false);
                    localStorage.removeItem('loginState');
                  }}
                />
              </span>
            ) : (
              <span>Try Again</span>
            )
          }
          onButtonClick={handleTryAgainClick}
          icon={loginBrokenImg}
        />
      )}
    </div>
  );
};

export default LoginForm;
