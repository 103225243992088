import { auth } from '@/firebase.config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Cookies from 'js-cookie';

import { apiService } from '../api/apiConfig';
import { store } from '../store';
import { setUser } from '../store/slices/auth';
import { AuthResponse, SignupCredentials } from './types/auth.types';

const handleAuthSuccess = (response: AuthResponse): void => {
  // Cookies.set('jwtToken', response.token);
  // Cookies.set('refreshToken', response.refreshToken);
  // store.dispatch(setUser(response.user));
};

const handleAuthError = (error: any): Error => {
  clearAuth();
  return error instanceof Error ? error : new Error('Authentication failed');
};

const clearAuth = (): void => {
  Cookies.remove('jwtToken');
  Cookies.remove('refreshToken');
  store.dispatch(setUser(null));
};

export const authService = {
  signup: async (credentials: SignupCredentials): Promise<AuthResponse> => {
    try {
      // sign-up api call
      const response = await apiService.request<AuthResponse>({
        method: 'POST',
        url: '/auth/sign-up',
        data: credentials
      });

      // sign in with Firebase
      const firebaseAuth = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

      // get Firebase token
      const firebaseToken = await firebaseAuth.user.getIdToken();

      // store tokens and user data
      Cookies.set('firebaseToken', firebaseToken);

      handleAuthSuccess(response);
      return response;
    } catch (error) {
      throw handleAuthError(error);
    }
  },

  login: async (credentials: { email: string; password: string }): Promise<void> => {
    try {
      // sign in with Firebase
      const firebaseAuth = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

      // get Firebase token
      const firebaseToken = await firebaseAuth.user.getIdToken();

      // store token
      Cookies.set('firebaseToken', firebaseToken);

      // You might want to store the user in Redux too
      // store.dispatch(setUser(firebaseAuth.user));
    } catch (error) {
      throw handleAuthError(error);
    }
  },

  logout: async (): Promise<void> => {
    try {
      await apiService.request({
        method: 'POST',
        url: '/auth/logout'
      });
    } finally {
      clearAuth();
    }
  },

  refreshToken: async (refreshToken: string): Promise<void> => {
    try {
      const response = await apiService.request<{ token: string; refreshToken: string }>({
        method: 'POST',
        url: '/auth/refresh-token',
        data: { refreshToken }
      });

      Cookies.set('jwtToken', response.token);
      Cookies.set('refreshToken', response.refreshToken);
    } catch (error) {
      clearAuth();
      throw error;
    }
  },

  isAuthenticated: (): boolean => {
    return !!Cookies.get('jwtToken');
  }
};
